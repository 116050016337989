import axiosWithHeaders from '../../services/api';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
  addContact({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.post("add-contact", data)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  
  getContact({ commit }, contactId) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`contact/${contactId}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  
  getContacts({ commit }) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`contacts`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  
  getActiveContacts({ commit }) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`contacts-active`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  
  setActiveOrInactiveContact({ commit }, contactId) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put(`set-contact/${contactId}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  
  updateContact({ commit }, contactData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put("update-contact/" + contactData.id, contactData)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  deleteContact({ commit }, contactId) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.delete(`delete/${contactId}/contact`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  
  addOrUpdateContact({ commit }, contactData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.post("add-update-contact", contactData)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
};